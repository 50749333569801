@font-face {
	font-family: 'Loubag-Bold';
	src: url('../../assets/loubag-bold/loubag-bold.eot');
	src: url('../../assets/loubag-bold/loubag-bold.eot?#iefix') format('embedded-opentype'),
             url('../../assets/loubag-bold/loubag-bold.woff2') format('woff2'),
	     url('../../assets/loubag-bold/loubag-bold.woff') format('woff'),
	     url('../../assets/loubag-bold/loubag-bold.ttf') format('truetype'),
	     url('../../assets/loubag-bold/loubag-bold.svg#youworkforthem') format('svg');
	font-weight: normal;
	font-style: normal;
}

h1 {
    font-family: "Loubag-Bold", sans-serif;
    font-size: 2rem;
}

.text-reflect, .text-reflect:before, .text-reflect span {
    display: block;
    margin: 0;
    padding: 0;
}

.text-reflect, .text-reflect:before, .text-reflect:after {
    position: relative;
}

 .text-reflect {
    padding-top: 9rem;
}

.text-reflect:after {
    display: flex;
    flex-direction: column;
    content: 'come back soon for updates!';
    backface-visibility: visible;
    z-index: 1;
    font-size: 0.5em;
    font-family: "Loubag-Ultralight";
    left: 8px; 
    letter-spacing: 0.2rem;
}

.in-progress {
    height: 60vh;
}

@media only screen and (max-width: 520px) {
    h1 {
        font-size: 1.5rem;
    }
}

@media only screen and (max-width: 420px) {
    h1 {
        font-size: 1.2rem;
    }
}