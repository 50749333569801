body {
    margin: 0;
    color: black;
    background-color: #E3F0E4;
}

*, :after, :before {
    box-sizing: border-box;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

.clearfix:after, .clearfix:before {
    content: '';
    display: table;
}

.clearfix:after {
    clear: both;
    display: block;
}

.machine-wrap {
    position: relative;
    animation: moveMachine 10s linear infinite;
}

.machine {
    width: 475px;
    height: 145px;
    padding-bottom: 20px;
}

.machine .machine-roof,
.machine .machine-main {
    margin: auto;
}

.machine-wrap .mud:before,
.machine-wrap .mud:after,
.machine .machine-roof .machine-silencer:before,
.machine .machine-roof .machine-silencer:after,
.machine .machine-body:before,
.machine .machine-body:after,
.machine .machine-main .machine-back:before,
.machine .machine-main .machine-back:after,
.machine .machine-main .machine-front:after {
    content: '';
    position: absolute;
}

.machine .machine-roof {
    width: 80px;
    height: 60px;
    bottom: -1px;
    position: relative;
    border: 15px solid #271e01;
    border-bottom: none;
}

.machine .machine-roof .machine-silencer {
    width: 14px;
    left: -50px;
    height: 12px;
    bottom: -1px;
    position: absolute;
    background: #271e01;
}

.machine .machine-roof .machine-silencer:before {
    height: 0;
    bottom: 100%;
    width: inherit;
    border: 6px solid #271e01;
    border-top: 4px solid transparent;
    border-left: 8px solid transparent;
}

.machine .machine-roof .machine-silencer:after {
    left: 4px;
    width: 5px;
    height: 12px;
    bottom: 100%;
    background: inherit;
    transform: skewY(-35deg);
}

.machine .machine-roof .machine-silencer .machine-smoke {
    left: -5px;
    width: 16px;
    height: 16px;
    bottom: 20px;
    border-radius: 50%;
    position: absolute;
    background: #271e01;
    transform: scale(0);
    animation: moveSmoke .5s linear infinite;
}

.machine-main {
    height: 60px;
    width: 185px;
    position: relative;
}

.machine .machine-main .machine-body {
    height: inherit;
    width: inherit;
    position: relative;
    background: #271e01;
}

.machine .machine-main .machine-body:before {
    left: -15px;
    width: 30px;
    height: inherit;
    background: inherit;
    transform: skewX(-20deg);
}

.machine .machine-main .machine-body:after {
    bottom: 0;
    right: 15px;
    width: 35px;
    height: 41px;
    background: #FCA5D9;
}

.machine .machine-main .machine-back {
    bottom: -1px;
    width: 75px;
    height: 45px;
    position: absolute;
}

.machine .machine-main .machine-back:before {
    width: 75px;
    height: 45px;
    background: #FCA5D9;
    border-radius: 50% 50% 0 0 / 85% 85% 0 0;
}

.machine .machine-main .machine-back:after {
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-top: -15px;
    margin-left: -30px;
    background: transparent;
    border: 15px solid #271e01;
}

.machine .machine-main .machine-front {
    z-index: 1;
    width: 60px;
    height: 60px;
    right: -24px;
    bottom: -20px;
    border-radius: 50%;
    position: absolute;
    background: #271e01;
    border-top: 2px solid #FCA5D9;
}

.machine .machine-main .machine-front:after {
    top: 50%;
    left: 50%;
    width: 80px;
    height: 20px;
    margin-top: -10px;
    margin-left: -40px;
    background: inherit;
    border: 2px solid #FCA5D9;
}

.machine-wrap .machine-roof,
.machine-wrap .machine-body,
.machine-wrap .machine-silencer,
.machine-wrap .machine-back:before,
.machine-wrap .machine-front {
    animation: upDown .6s linear infinite;
}

.machine-wrap .machine-silencer {
    animation-duration: .4s;
}

.machine-wrap .machine-front {
    animation-duration: .3s;
}

.machine-wrap .machine-back {
    animation: upDown .8s linear infinite;
}

@keyframes moveSmoke {
    0% {
        opacity: 0;
        transform: scale(0) translate(0);
    }

    60% {
        transform: scale(.5) translate(0);
    }

    100% {
        opacity: .6;
        transform: scale(1) translate(-10px, -40px);
    }
}

@keyframes upDown {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(2px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes moveMachine {
    0% {
        left: -500px;
    }
    100% {
        left: 120%;
    }
}