@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.container {
    padding-right: 2rem;
}

.wyns-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
}

.logo {
    display: flex;
    align-items: center;
}

.logo img {
    width: 5em;
}

#logo-text {
    font-size: 0.7rem;
    font-family: "Loubag-Bold";
    margin: 0;
}

.navbar-nav {
    font-family: "Loubag-Regular";
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
    margin-left: 20%;
}

.navbar-nav .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
}

.dropdown-menu {
    padding: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    border-radius: 0;
    margin: 0.5rem;
}

.dropdown-item {
    color: #333;
    padding: 0.5rem;
    font-size: 0.8rem;
    text-align: center;
    font-family: "Montserrat";
}

.dropdown-item:hover {
    text-decoration: underline;
    background-color: white;
}

.dropdown-item.active {
    background-color: #FB9ED5; 
    color: #53193c;
}

.wyns-socials {
    display: flex;
    flex-direction: column;
    align-items: center; 
    gap: 0.5rem;
    font-size: 0.8rem;
    font-family: "Montserrat", sans-serif;
}

@media only screen and (max-width: 768px) {
    .wyns-nav {
        flex-direction: column;
        text-align: center;
    }

    .navbar-nav {
        flex-wrap: wrap;
    }

    .dropdown-menu {
        left: 50%;
        transform: translateX(-50%);
    }

    .wyns-socials {
        font-size: 0.7rem;
    }
}

@media only screen and (max-width: 450px) {
    .logo img {
        width: 3em;
    }

    #logo-text {
        font-size: 0.8rem;
    }
}
